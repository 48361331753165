import { Link, Route, Routes } from 'react-router-dom';
import { getHeader, baseURL, axios } from './utils.js';
import './App.css';
import React from 'react';
import { Layout, Menu, Row, Col, Avatar, Button, ConfigProvider } from 'antd';
import {
    UserOutlined,
    AccountBookOutlined,
    BulbOutlined,
    DollarCircleOutlined,
    AlertOutlined,
    EuroCircleOutlined
} from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';

import Product from './pages/random/index';
import Banner from './pages/banner/index';
import Analysis from './pages/analysis/index';
// import Home from './pages/home/index';
import User from './pages/user/index';
import Login from './pages/login/index';
// import Config from './pages/config/index';
import Regular from './pages/regular/index';
import Envelope from './pages/envelope/index';
import Permission from './pages/permission/index';
import Status from './pages/status/index';


// import Score from './pages/score/index';



const { Header, Content, Sider } = Layout;

class App extends React.Component {
    componentDidMount() {

        const hash = window.location.hash;

        if (localStorage.getItem('token')) {
            const username = localStorage.getItem('username') || '管理员';
            this.setState({
                isLogin: true,
                username
            });
        } else {
            this.setState({
                isLogin: false
            });
        }

        let defaultCurrent = 'module';
        
        if (hash.includes('home')) {
            defaultCurrent = 'home';
        } else if (hash.includes('user')) {
            defaultCurrent = 'user';
        } else if (hash.includes('random')) {
            defaultCurrent = 'random';
        } else if (hash.includes('regular')) {
            defaultCurrent = 'regular';
        } else if (hash.includes('analysis')) {
            defaultCurrent = 'analysis';
        } else if (hash.includes('envelope')) {
            defaultCurrent = 'envelope';
        } else if (hash.includes('permission')) {
            defaultCurrent = 'permission';
        } else if (hash.includes('banner')) {
            defaultCurrent = 'banner';
        } else if (hash.includes('status')) {
            defaultCurrent = 'status';
        }

        this.setState({
            current: defaultCurrent
        })
    }


    state = {
        isLogin: false
    };

    handleMenuClick = (e) => {
        this.setState({
            current: e.key,
        });
    }

    logout = () => {
        localStorage.setItem('token', '');
        localStorage.setItem('username', '');
        localStorage.setItem('userId', '');
        this.setState({
            isLogin: false,
            username: ''
        });
    }

    render() {
        const { username, current, showTip, currentWallet = {} } = this.state;
        const role = localStorage.getItem('role')
        console.log(this.state.isLogin);
        return (
            <div>
                <ConfigProvider locale={zhCN}>
                    {
                        !this.state.isLogin ?
                            <Content style={{ margin: '0 16px' }}>
                                <Routes>
                                    <Route path="*" element={<Login />} />
                                </Routes>
                            </Content>
                            : (

                                <Layout style={{ minHeight: '100vh' }}>
                                    <Sider>
                                        <div className="logo">富国</div>
                                        <Menu theme="dark"
                                            defaultSelectedKeys={['module']}
                                            selectedKeys={[current]}
                                            mode="inline"
                                            onClick={this.handleMenuClick}>
                                            <Menu.Item key="user" icon={<UserOutlined />}>
                                                <Link to="/user">用户列表</Link>
                                            </Menu.Item>
                                            {/* <Menu.Item key="random" icon={<EuroCircleOutlined  />}>

                      <Link to="/random">随机红包配置</Link>
                    </Menu.Item> */}
                                            <Menu.Item key="regular" icon={<BulbOutlined />}>

                                                <Link to="/regular">红包配置</Link>
                                            </Menu.Item>

                                            
                                            <Menu.SubMenu title="红包领取" key="hongbao" icon={<DollarCircleOutlined />}>
                                                <Menu.Item key="analysis">
                                                    <Link to="/analysis">数据概况</Link>
                                                </Menu.Item>
                                                <Menu.Item key="envelope">
                                                    <Link to="/envelope">红包列表</Link>
                                                </Menu.Item>
                                            </Menu.SubMenu>

                                            {
                                                Number(role) === 1 ? <Menu.Item key="permission" icon={<BulbOutlined />}>

                                                    <Link to="/permission">权限管理</Link>
                                                </Menu.Item> : null
                                            }
                                            <Menu.Item key="banner" icon={<UserOutlined />}>
                                                <Link to="/banner">banner配置</Link>
                                            </Menu.Item>

                                            <Menu.Item key="status" icon={<BulbOutlined />}>

                                                <Link to="/status">活动状态设置</Link>
                                            </Menu.Item>


                                        </Menu>
                                    </Sider>
                                    <Layout className="site-layout">
                                        <Header className="site-layout-background" style={{ padding: 0, background: '#fff' }}>
                                            <Row>
                                                <Col span={15} offset={1}>
                                                    后台管理系统
                                                </Col>
                                                <Col span={3} offset={5}>
                                                    <Avatar icon={<UserOutlined />} style={{ marginRight: 10 }} />
                                                    {username}
                                                    <Button type="link" onClick={this.logout}>退出</Button>

                                                </Col>
                                            </Row>

                                        </Header>

                                        <Content style={{ margin: '0 16px' }}>

                                            <Routes>
                                                {/* <Route path="/random" element={<Product />} /> */}
                                                <Route path="/" element={<Analysis />} key="/" />
                                                <Route path="/user" element={<User />} key="user" />
                                                <Route path="/regular" element={<Regular />} key="regular" />
                                                <Route path="/analysis" element={<Analysis />} key="analysis" />
                                                <Route path="/banner" element={<Banner />} key="banner" />
                                                <Route path="/envelope" element={<Envelope />} key="envelope" />
                                                <Route path="/permission" element={<Permission />} key="permission" />

                                                <Route path="/status" element={<Status />} key="status" />

                                                
                                            </Routes>

                                        </Content>
                                        {/* <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer> */}
                                    </Layout>
                                </Layout>
                            )
                    }
                </ConfigProvider>
            </div>
        );
    }
}

export default App;
