import 'moment/locale/zh-cn';
import './index.css';
// import moment from 'moment';
import React from 'react';
import { Row, Col, Table, Button, message, DatePicker, Modal, Form, Input, Radio } from 'antd';

import {
  ExclamationCircleOutlined,
  DashboardTwoTone
} from '@ant-design/icons';

import { getHeader, baseURL, axios } from '../../utils';


const { RangePicker } = DatePicker;
const { confirm } = Modal;

class Home extends React.Component {
  state = {
    list: [],
    totalUserCount: 30,
    isAdd: true,
    tagModalVisible: false,
    currentTag: {},
    currentType: 1,
    page: 1,
    limit: 10
  }

  componentDidMount() {
    this.getData();
  }


  downLoad = () => {
    const { start_time, end_time } = this.state;
    const a_link = document.createElement("a"); 
    window.open(baseURL + `/api/small_room_excel?start_time=${start_time || ''}&end_time=${end_time || ''}`);
    // a_link.href = `/api/small_room_excel?start_time=${start_time || ''}&end_time=${end_time || ''}`;
    console.log(a_link.href);
    a_link.download = '访问记录';
    console.log(a_link.download);
    // a_link.click();
  }

  getData(page = 1, params = {}) {
    message.loading();
    axios({
      url: baseURL + '/admin_api/readpacks',
      method: 'get',
      headers: getHeader(),
      params: {
        level_id: this.state.currentType, //  等级
        // page: this.state.page,
        // limit: this.state.limit
      },
    }).then(res => {
      console.log('红包', res)
      const data = res.data
      if(data.code === 200) {
        this.setState({
          list: data.data,
          // totalUserCount: data.data.total || 0
        })
      } else {
        message.error('获取失败，请稍后重试')
      }
      

      message.destroy()

    })
  }

 

  edit(packData) {
    this.setState({
      tagModalVisible: true,
      isAdd: false,
      currentTag: packData
    });
  }

  add() {
    this.setState({
      currentTag: {},
      isAdd: true,
      tagModalVisible: true
    });
  }
  delete(id) {
    confirm({
      title: '确认要删除选中?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: '确定',
      cancelText: '取消',
      onOk: () => {
        axios({
          url: baseURL + `/admin_api/readpacks/${id}`,
          method: 'delete',
          headers: getHeader(),
        }).then(res => {
          const data = res.data || {};
          message.success(data.msg);
          this.getData(this.state.page);

        });
      },
      onCancel() { },
    });
  }

  tagSubmit() {
    const { 
      num, income, type, readpack_param, act_no
    } = this.currentForm.getFieldsValue();
    if (!num) {
      message.warning('请填写人数');
      return
    } else if(!income) {
      message.warning('请填写金额');
      return
    } else if(!type) {
      message.warning('请选择类型');
      return
    } else if(!readpack_param) {
      message.warning('请填写红包参数');
      return
    } else if (!act_no) {
      message.warning('请填写活动编号');
      return
    }

    const { isAdd, currentTag, currentType } = this.state;

    const data = {
      num, income, type, readpack_param, level_id: currentType, act_no
    }

    axios({
      url: baseURL + (isAdd ? '/admin_api/readpacks' : `/admin_api/readpacks/${currentTag.id}`),
      method: isAdd ? 'post': 'put',
      headers: getHeader(),
      data: data
    }).then(res => {
      const data = res.data || {};
      if (data.code === -1) {
        message.error(isAdd ? '添加失败' : '编辑失败');
      } else {
        message.success(isAdd ? '添加成功' : '编辑成功');
        this.setState({
          tagModalVisible: false
        });
        this.getData();
      }
    });
  }

  changeGrand(item) {
    if(item.key === this.state.currentType) return
    this.setState({
      currentType: item.key
    },() => {
      // 请求数据
      this.getData()
    })
    
  }


  pageChange = (page, pageSize) => {
    this.getData(page);
  }

  render() {
    const role = localStorage.getItem('role')
    const columns = [
      // {
      //   title: 'id',
      //   dataIndex: 'id',
      // },
      {
        title: '门槛人数',
        dataIndex: 'num'
      },
      {
        title: '红包金额（元）',
        dataIndex: 'income'
      },
      {
        title: '红包类型',
        dataIndex: 'type',
        render: (type, record) => (
          <span>{type == 1 ? '拉新红包': '额外红包'}</span>
        )
          
        
      },
      {
        title: '红包参数',
        dataIndex: 'readpack_param'
      },
      {
        title: '活动编号',
        dataIndex: 'act_no'
      },
      {
        title: '创建时间',
        dataIndex: 'created_at'
      },
      {
        title: '修改时间',
        dataIndex: 'updated_at'
      },
      
    ];
    if(Number(role) === 1) {
      columns.push({
        title: '操作',
        dataIndex: 'id',
        render: (id, record) => (
          <div>
            <Button type="link" onClick={() => this.edit(record)}>编辑</Button>
            <Button type="link" onClick={() => this.delete(id)}>删除</Button>
          </div>
        )
      })
    }

    const {
      list,
      totalUserCount,
      isAdd,
      tagModalVisible,
      currentTag,
      currentType,
      limit
    } = this.state;

    const gradList = [
      { label: 'V1', key: '1',  },
      { label: 'V2', key: '2',  },
      { label: 'V3', key: '3', },
      { label: 'V4', key: '4', },
      { label: 'V5', key: '5', },
      { label: 'V6', key: '6', },
      { label: 'V7', key: '7',  },
      { label: 'V8', key: '8',  },
      { label: 'V9', key: '9',  },
    ]



    return (
      <div>
        <Row style={{ margin: "20px 0", background: '#fff', borderRadius: 20 }}>
          <Col span={4} style={{padding: 20}}>
            <div style={{backgroundColor: '#efefef'}}>
              <ul style={{listStyle: 'none', padding: 0, backgroundColor: '#efefef', textAlign: 'center'}}>
                {
                  gradList.map(item =>{
                    return <li 
                      key={item.key}
                      style={{borderBottom: '1px solid #ddd', lineHeight: 3, cursor: 'pointer' ,backgroundColor: item.key == currentType? '#ccc7c7' : ''}}
                      onClick={() => this.changeGrand(item)}
                    >{item.label}</li>
                  })
                }
                <li style={{borderBottom: '1px solid #ddd', backgroundColor: "#aaa", lineHeight: 3}}></li>
              </ul>
            </div>
          </Col>
          <Col flex="auto" style={{ background: '#fff', borderRadius: 20, padding: '20px 0' }} span={20}>
            <Row >
              {
                Number(role) === 1 ?  <Button type='primary' style={{marginLeft: '20px'}} onClick={() => this.add()}>新增红包</Button>: null
              }
             
            </Row>

            <div style={{padding: 20}}>
              <Table 
                rowKey={record => record.id} 
                // pagination={{
                //   defaultPageSize: limit,
                //   total: totalUserCount,
                //   showSizeChanger: false,
                //   hideOnSinglePage: true,
                //   onChange: (page, pageSize) => {
                //     this.pageChange(page, pageSize)
                //   }
                // }} 
                pagination={{
                  hideOnSinglePage: true,
                  pageSize: 20
                }}
              dataSource={list || []} columns={columns}  />
            </div>

          </Col>
        </Row>
        <Modal
          title={isAdd ? '新增' : '编辑'}
          centered
          destroyOnClose={true}
          visible={tagModalVisible}
          onOk={() => this.tagSubmit()}
          onCancel={() => { this.setState({ tagModalVisible: false }) }}
          width={500}
        >

          <Form preserve={false} ref={e => { this.currentForm = e; }} initialValues={currentTag}>
            
            <Form.Item label="门槛人数" name='num' rules={[{ required: true, message: '请录入人数' }]}>
              <Input placeholder="请输入门槛人数" type="number"/>
            </Form.Item>
            <Form.Item label="红包金额" name='income' rules={[{ required: true, message: '请录入金额' }]}>
              <Input placeholder="请输入红包金额"/>
            </Form.Item>
            <Form.Item label="红包类型" name='type' rules={[{ required: true, message: '请选择类型' }]}>
              <Radio.Group>
                <Radio value={1}>拉新红包</Radio>
                <Radio value={2}>额外红包</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="活动编号" name='act_no' rules={[{ required: true, message: '请输入活动编号' }]}>
              <Input placeholder="请输入活动编号"/>
            </Form.Item>
            <Form.Item label="红包参数" name='readpack_param' rules={[{ required: true, message: '请输入红包参数' }]}>
              <Input placeholder="请输入红包参数"/>
            </Form.Item>
            
          </Form>
        </Modal>
      </div>
    );
  }
}

export default Home;
