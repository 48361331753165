import React from 'react';
import { Row, Col, Table, Input, Button, Modal, Form, message, Cascader, Select, Upload, DatePicker } from 'antd';
import './index.css';

import { getHeader, baseURL, axios } from '../../utils';
const { RangePicker } = DatePicker;


class Home extends React.Component {
    state = {
        userModalVisible: false,
        moreList: [

        ],
        managerList: [],
        currentForm: {
            search: '',
            data: null
        },
        totalUserCount: 40,
        defaultSize: 20
    }

    componentDidMount() {
        this.getList();
    }

    getList(params = null) {
        message.loading('拉取数据中。。。');

        const defaultData = {
            page: 1,
            limit: this.state.defaultSize,
            search: null,
            start: null,
            end: null
        }
        if (params && params.date) {
            delete params.date
        }
        console.log('请求参数', { ...defaultData, ...params })
        axios({
            url: baseURL + '/admin_api/app_users',
            method: 'get',
            headers: getHeader(),
            params: {
                ...defaultData, ...params
            }
        }).then(res => {
            const data = res.data;
            console.log('user', res)
            if (data.code === 200) {
                this.setState({
                    managerList: data.data?.data || [],
                    totalUserCount: data.data?.total || 0,
                    copyParams: { ...defaultData, ...params }
                });
            } else {
                message.error('获取失败，请稍后重试')
            }
            message.destroy();

        });
    }

    pageChange(page) {
        const data = {
            ... this.currentForm.getFieldsValue()
        }
        if (data.date) {
            data.start = data.date[0].format('YYYY-MM-DD')
            data.end = data.date[1].format('YYYY-MM-DD')
        }
        this.getList({
            ...data, page: page
        })

    }

    search() {
        const data = {
            ... this.currentForm.getFieldsValue()
        }
        if (data.date) {
            data.start = data.date[0].format('YYYY-MM-DD')
            data.end = data.date[1].format('YYYY-MM-DD')
            // console.log(data.date[0].format('YYYY-MM-DD HH:mm'))
        }

        this.getList(data)
    }

    reset() {
        this.currentForm.resetFields()
        this.search()
    }


    handleExportClick = () => {
        // function exportFile(url, data) {
        axios({
            url: baseURL + '/admin_api/app_users',
            method: 'get',
            headers: getHeader(),
            params: {
                ...this.state.copyParams,
                limit: null,
                export: 1
            },
            responseType: 'blob'
        }).then((res) => {
            const content = res.data;
            const blob = new Blob([content]);
            const fileName = "导出用户数据.xls";
            const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL'];
            let elink = document.createElement('a');
            if ('download' in elink) {
                elink.download = fileName;
                elink.style.display = 'none';
                elink.href = selfURL['createObjectURL'](blob);
                document.body.appendChild(elink);

                // 触发链接
                elink.click();
                selfURL.revokeObjectURL(elink.href);
                document.body.removeChild(elink)
            } else {
                navigator.msSaveBlob(blob, fileName);
            }
        })
        // }
    }


    // 详情
    showMore(id) {
        this.setState({
            userModalVisible: true
        });
        axios({
            url: baseURL + '/admin_api/statistics/app_users/inviter',
            method: 'get',
            headers: getHeader(),
            params: {
                id
            }
        }).then(res => {
            const data = res.data;
            if (res.status === 200) {
                // 处理下数据
                let array = []
                data.data.data.forEach((i) => {
                    array.push(
                        {
                            id: i.reg_user_id,
                            nikename: i.reg_user.nikename,
                            phone: i.reg_user.phone,
                            regtime: i.reg_user.regtime,
                            income: i.income
                        }
                    )
                })
                this.setState({
                    moreList: array || []
                });
            } else {
                this.setState({
                    userModalVisible: false
                });
                message.error('获取失败，请稍后重试')
            }
            message.destroy();


        });
    }

    exportUser() {
        // window.location.href
    }

    render() {

        const {
            managerList = [],
            userModalVisible,
            moreList = [],
            currentForm,
            totalUserCount,
            defaultSize
        } = this.state;

        const columns = [
            {
                title: '用户id',
                dataIndex: 'id'
            },
            {
                title: '昵称 ',
                dataIndex: 'nikename'
            },
            {
                title: '手机号 ',
                dataIndex: 'phone'
            },
            {
                title: '注册时间',
                dataIndex: 'regtime'
            },

            {
                title: '红包金额',
                dataIndex: 'income'
            }
        ];
        //     phone - 手机号
        // user_indent - 用户编号
        // nikename - 用户名称
        // regtime - 注册时间
        // level_id - 用户等级
        // number - 邀请用户数
        // income - 获得红包数


        const userColumns = [
            {
                title: 'id',
                dataIndex: 'id',
                // specify the condition of filtering result
                // here is that finding the name started with `value`
            },
            {
                title: '富国用户id',
                dataIndex: 'user_indent'
            },
            {
                title: '富国用户等级',
                dataIndex: 'level_id'
            },

            {
                title: '昵称 ',
                dataIndex: 'nikename'
            },
            {
                title: '手机号 ',
                dataIndex: 'phone'
            },
            {
                title: '注册时间',
                dataIndex: 'regtime'
            },
            {
                title: '邀请人数',
                dataIndex: 'number',
                render: (number, data) => (
                    <Button type="link" onClick={() => this.showMore(data.id)}>{number}</Button>
                )
            },
            {
                title: '红包金额',
                dataIndex: 'income'
            }
        ];


        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>

                <Row flex="auto" style={{ background: "#fff", borderRadius: 20, marginTop: 20, padding: '20px 20px' }}>
                    <Col span={24}>
                        <Form ref={e => { this.currentForm = e; }}>
                            <Row>

                                <Col span={8}>
                                    <Form.Item label="搜素用户" name='search' value={currentForm.search}>
                                        <Input placeholder="用户id/昵称/手机号" />
                                    </Form.Item>
                                </Col>
                                <Col span={10} offset={1}>
                                    <Form.Item label="注册日期" name='date' value={currentForm.date}>
                                        <RangePicker format="YYYY-MM-DD" placeholder={['开始时间', '结束时间']} />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item>
                                        <Button type='primary' onClick={() => this.search()}>查询</Button>
                                        <Button onClick={() => this.reset()}>重置</Button>
                                    </Form.Item>
                                </Col>

                            </Row>
                        </Form>
                    </Col>
                    <Col span={24}>
                        <Button type="link" onClick={this.handleExportClick}>导出</Button>
                    </Col>

                    <Col span={24} style={{ padding: '20px' }}>
                        <Table
                            pagination={{
                                total: totalUserCount,
                                pageSize: defaultSize,
                                showSizeChanger: false,
                                hideOnSinglePage: true,
                                onChange: (page, pageSize) => {
                                    this.pageChange(page)
                                },
                                locale: true
                            }}
                            rowKey={record => record.id}
                            columns={userColumns}
                            dataSource={managerList}
                        />
                    </Col>
                </Row>


                <Modal
                    title='邀请列表'
                    centered
                    destroyOnClose={true}
                    visible={userModalVisible}
                    onCancel={() => { this.setState({ userModalVisible: false }) }}
                    width={800}
                    footer={null}
                >
                    <Row>
                        <Table style={{ width: '100%' }} rowKey={record => record.id} columns={columns} dataSource={moreList} />
                    </Row>


                </Modal>
            </div >
        );
    }
}

export default Home;
