import './index.css';
import React from 'react';
import { Row, Col, Table, Button, message, TreeSelect, Modal, Radio, Form, Input } from 'antd';
import {
  DashboardTwoTone,
  UploadOutlined,
} from '@ant-design/icons';

import { getHeader, baseURL, axios } from '../../utils';

const { Search } = Input;

class Home extends React.Component {

  componentDidMount() {
    this.getTagList();
  }
  state = {
    tagList: [],
    page: 1,
    limit: 10,
    total: 0
  }

  getTagList() {
    axios({
      url: baseURL + '/admin_api/statistics/readpacks/group',
      method: 'get',
      headers: getHeader(),
      params: {
        page: this.state.page,
        limit: this.state.limit
      }
    }).then(res => {
      const data = res.data || [];
      if (data.code === -1) {
        message.error('获取失败，请稍后重试')
      } else {
        this.setState({
          tagList: data.data.data || [],
          total: data.data.total || 0
        })
      }

    });
  }


  pageChange = (page, pageSize) => {
    this.setState({
      page
    }, () => {
      this.getTagList()
    })
  }

  handleExportClick = () => {
    // function exportFile(url, data) {
      axios({
        url: baseURL + '/admin_api/statistics/readpacks/group',
        method: 'get',
        headers: getHeader(),
        params: {
          page: this.state.page,
          export:1
        },
        responseType: 'blob'
      }).then((res) => {
          const content = res.data;
          const blob = new Blob([content]);
          const fileName = "导出数据.xls";
          const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL'];
          let elink = document.createElement('a');
          if ('download' in elink) {
              elink.download = fileName;
              elink.style.display = 'none';
              elink.href = selfURL['createObjectURL'](blob);
              document.body.appendChild(elink);
  
              // 触发链接
              elink.click();
              selfURL.revokeObjectURL(elink.href);
              document.body.removeChild(elink)
          } else {
              navigator.msSaveBlob(blob, fileName);
          }
      })
    // }
  }




 

 


  

 

  render() {


    const columns = [
      // {
      //   title: 'id',
      //   dataIndex: 'id',
      //   key: 'id'
      //   // specify the condition of filtering result
      //   // here is that finding the name started with `value`
      // },
      {
        title: '红包金额',
        dataIndex: 'income',
        key: 'income'
      },

      {
        title: '领取人数',
        dataIndex: 'num',
        key: 'num'
      }
    ];

   

    

    const { 
      tagList = [],
      page,
      limit, 
      total
    } = this.state;


    return (
      <div>
        <Row style={{ padding: "20px 0", height: 700 }}>
          <Col flex="auto" style={{ background: '#fff', borderRadius: 20, padding: '20px' }}>
            <Row>
                
                <Button type='primary' onClick={this.handleExportClick}>导出</Button>
              
            </Row>

            <div style={{marginTop: '20px'}}>
              
              <Table
                rowKey={(record, index) => index}
                pagination={{
                  total: total,
                  pageSize: limit,
                  showSizeChanger: false,
                  hideOnSinglePage: true,
                  onChange: (page, pageSize) => {
                    this.pageChange(page)
                  },
                  locale: true
                }}
                dataSource={tagList || []}
                columns={columns}   
              />
            </div>

          </Col>
        </Row>


      </div>
    );
  }

}
export default Home;