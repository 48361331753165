import React from 'react';
import { Row, Col, Button, message, DatePicker, Spin } from 'antd';
import moment from 'moment';
import * as echarts from "echarts/lib/echarts";
import './index.css';





import { getHeader, baseURL, axios } from '../../utils';

import { GridComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

const { RangePicker } = DatePicker;
echarts.use([GridComponent, LineChart, CanvasRenderer, UniversalTransition]);




class Home extends React.Component {
  // newUsers - 新用户数
  // oldUsers - 老用户数
  // users - 注册用户数
  // readpackCount - 红包个数
  // readpackIncomes - 红包价格
  state = {
    analysisType: 'day',
    analysis: {
      newUsers: 122,
      oldUsers: 344,
      share: 35,
      users: 22,
      readpackCount: 55,
      readpackIncomes: 9999,
    },
    chartUserType: 'week',
    chartPriceType: 'week',
    userSpinning: false
  }

  componentDidMount() {
    this.getAnalysis('day')
    this.getChartOfNewUserData()
    this.getChartOfHongbaoData()


  }
  // 
  getAnalysis(type = 'day') {
    message.loading('拉取数据中。。。');
    axios({
      url: baseURL + (type === 'day' ? '/admin_api/statistics/today' : '/admin_api/statistics/all'),
      method: 'get',
      headers: getHeader(),
    }).then(res => {
      const data = res.data;
      console.log('今日数据', res)
      if (data.code === 200) {
        this.setState({
          analysis: data?.data || {
            newUsers: 0,
            oldUsers: 0,
            share: 0,
            users: 0,
            readpackCount: 0,
            readpackIncomes: 0,
          }
        });
      } else {
        message.error('获取失败，请稍后重试')
      }

      message.destroy();

    });
  }

  getAnalysisAction(type) {
    const { analysisType } = this.state
    if (type === analysisType) return
    this.setState({
      analysisType: type
    })
    this.getAnalysis(type)
  }

  tagChangeTime(type = 'user', timeType = null) {
    if (type === 'user') {
      const { chartUserType } = this.state
      if (chartUserType === timeType) return
      this.setState({
        chartUserType: timeType
      })
      this.getChartOfNewUserData()
    }
    if (type === 'hongbao') {
      const { chartPriceType } = this.state
      if (chartPriceType === timeType) return
      this.setState({
        chartPriceType: timeType
      })
      this.getChartOfHongbaoData()

    }
  }

  // 新用户数据
  getChartOfNewUserData(param = null) {
    this.setState({
      userSpinning: true
    })
    let params = {}
    const { chartUserType } = this.state
    if (param === null) {
      params.end = moment().format('YYYY-MM-DD')
      if (chartUserType === 'week') {
        params.start = moment(moment().subtract(7, 'days')).format('YYYY-MM-DD');

      }
      if (chartUserType === 'month') {
        params.start = moment(moment().subtract(30, 'days')).format('YYYY-MM-DD');
      }
      console.log(params)
    } else {
      params = param
    }
    axios({
      url: baseURL + '/admin_api/statistics/user_count',
      method: 'get',
      headers: getHeader(),
      params: params
    }).then(res => {
      const data = res.data;
      console.log('新用户数据', res)
      this.setState({
        userSpinning: false
      })
      if (data.code === 200) {
        let option = this.dealData(data.data, 'num')
        var chartDom = document.getElementById('newUser');
        var myChart = echarts.init(chartDom);
        option && myChart.setOption(option);

      } else {
        message.error('获取失败，请稍后重试')
      }

      message.destroy();

    });

  }

  dateChange = (date, dateString) => {
    let time = {}
    if (dateString) {
      time.start = dateString[0]
      time.end = dateString[1]
    }
    this.getChartOfNewUserData(time)

  }
  // 红包
  getChartOfHongbaoData(param = null) {
    this.setState({
      spinning: true
    })
    let params = {}
    const { chartPriceType } = this.state
    if (param === null) {
      params.end = moment().format('YYYY-MM-DD')
      if (chartPriceType === 'week') {
        params.start = moment(moment().subtract(7, 'days')).format('YYYY-MM-DD');

      }
      if (chartPriceType === 'month') {
        params.start = moment(moment().subtract(30, 'days')).format('YYYY-MM-DD');
      }
      console.log(params)
    } else {
      params = param
    }
    axios({
      url: baseURL + '/admin_api/statistics/incomes',
      method: 'get',
      headers: getHeader(),
      params: params
    }).then(res => {
      const data = res.data;
      console.log('红包数据', res)
      if (data.code === 200) {
        let priceChart = this.dealData(data.data, 'income')
        var chartDom2 = document.getElementById('priceChart');
        var myChart2 = echarts.init(chartDom2);
        priceChart && myChart2.setOption(priceChart);
        this.setState({
          spinning: false
        })

      } else {
        message.error('获取失败，请稍后重试')
      }

      message.destroy();

    });
  }
  dateChangeHongbao = (date, dateString) => {
    let time = {}
    if (dateString) {
      time.start = dateString[0]
      time.end = dateString[1]
    }
    this.getChartOfHongbaoData(time)
  }



  dealData(data = [], key) {
    let series = []
    let xAxisData = []
    data.map(item => {
      series.push(item[key])
      xAxisData.push(item.date)
    })
    return {
      xAxis: {
        type: 'category',
        data: xAxisData
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          data: series,
          type: 'line'
        }
      ]
    }
  }




  render() {

    const {
      analysisType,
      analysis,
      chartUserType,
      chartPriceType,
      spinning,
      userSpinning
    } = this.state;




    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ borderRadius: 10, backgroundColor: '#fff', marginTop: '20px' }}>
          <Row style={{ padding: 20, position: 'relative' }}>
            <h3 className='tit' >数据总览</h3>
            <div style={{ position: 'absolute', top: '25%', right: '20px' }}>
              <Button type={analysisType === 'day' ? 'primary' : ''} onClick={() => this.getAnalysisAction('day')}>当日统计</Button>
              <Button type={analysisType === 'all' ? 'primary' : ''} onClick={() => this.getAnalysisAction('all')}>历史统计</Button>
            </div>
          </Row>
          <Row style={{ padding: 20 }}>
            <Col span={4}>
              <p className="info">用户总数量</p>
              <p className="data">{analysis.users}</p>

            </Col>
            <Col span={4}>
              <p className="info">老用户统计</p>
              <p className="data">{analysis.oldUsers}</p>
            </Col>
            {/* <Col span={4}>
              <p className="info">分享分数</p>
              <p className="data">{analysis.share}</p>
            </Col> */}
            <Col span={4}>
              <p className="info">新用户统计</p>
              <p className="data">{analysis.newUsers}</p>
            </Col>
            <Col span={4}>
              <p className="info">发放红包数量</p>
              <p className="data">{analysis.readpackCount}</p>
            </Col>
            <Col span={4}>
              <p className="info">发放红包金额</p>
              <p className="data">{analysis.readpackIncomes}</p>
            </Col>
          </Row>
        </div>
        <div style={{ justifyContent: 'space-between', display: 'flex' }}>
          <div style={{ marginTop: 15, borderRadius: 10, backgroundColor: '#fff', width: '49%' }}>
            <div style={{ padding: 20 }}>
              <h3 className='tit' >新用户数据</h3>
              <div className="tiaojian">
                <div>
                  时间区间&nbsp;
                  <RangePicker onChange={this.dateChange} format="YYYY-MM-DD" />
                </div>
                <div>
                  <span className="tag" style={{ backgroundColor: chartUserType === 'week' ? '#ededed' : '' }} onClick={() => this.tagChangeTime('user', 'week')}>近7天</span>
                  <span className="tag" style={{ backgroundColor: chartUserType === 'month' ? '#ededed' : '' }} onClick={() => this.tagChangeTime('user', 'month')}>近30天</span>
                </div>
              </div>
              <Spin tip="Loading" size="large" spinning={userSpinning}>
                <div style={{ width: '88%', height: '400px' }} id="newUser"></div>
              </Spin>

            </div>

          </div>
          <div style={{ marginTop: 15, borderRadius: 10, backgroundColor: '#fff', width: '49%' }}>
            <div style={{ padding: 20 }}>
              <h3 className='tit' >发放红包金额</h3>
              <div className="tiaojian">
                <div>
                  时间区间&nbsp;
                  <RangePicker onChange={this.dateChangeHongbao} format="YYYY-MM-DD" />
                </div>
                <div>
                  <span className="tag" style={{ backgroundColor: chartPriceType === 'week' ? '#ededed' : '' }} onClick={() => this.tagChangeTime('hongbao', 'week')}>近7天</span>
                  <span className="tag" style={{ backgroundColor: chartPriceType === 'month' ? '#ededed' : '' }} onClick={() => this.tagChangeTime('hongbao', 'month')}>近30天</span>
                </div>
              </div>
              <Spin tip="Loading" size="large" spinning={spinning}>
                <div style={{ width: '88%', height: '400px' }} id="priceChart"></div>

              </Spin>

            </div>

          </div>
        </div>



      </div >
    );
  }
}

export default Home;
