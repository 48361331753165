import './index.css';
import React from 'react';
import {
  Row, Col, Input,  Button, Form, Layout,
  message
} from 'antd';

import {
  UserOutlined,
  LockOutlined
} from '@ant-design/icons';


import {  baseURL, axios } from '../../utils';
const { Content } = Layout;
class Login extends React.Component {

  componentDidMount() {
    
  }

  formFinish(formData) {
    message.loading();
    axios({
        url: baseURL + '/admin_api/auth/login',
        method: 'post',
        data: {
            ...formData
        }
    }).then(res => {
      const data = res.data;
      const { token, token_type, user } = data || {};
      
      if (res.status === 200) {
        const { localStorage } = window;
        // localStorage.setItem('token_type', token_type || '');
        localStorage.setItem('token', token);
        localStorage.setItem('role', user.role || 1);
        localStorage.setItem('userId', user.id || 1);
        localStorage.setItem('username', user.name || '管理员');

        const { hash, origin, pathname } = window.location;
        
        let newPathArr = pathname.split('/');
        newPathArr.pop();
        const newPath = origin + newPathArr.join('/');
        const newHash = hash ? hash : '';
        
        window.location.href = newPath + '/#/user';
        window.location.reload();
      } else {
        message.error('登录失败' + res.status);
      }

      message.destroy();
    }).catch(function (error) {
      message.error('登录失败');
    });
  }

  render() {

    return (
      <Layout>
        <Content>
          <Row style={{ background: '#fff', height: '100vh' }} justify="center" align="middle">
            <Col style={{ width: 360, marginTop: 0 }}>
              <div style={{ fontSize: 24, fontWeight: 'bold', marginBottom: 14 }}>登录</div>
              <Form onFinish={this.formFinish}>
                <Form.Item
                  name="email"
                  rules={[{ required: true, message: '请输入登录账号' }]}
                >
                  <Input size="large"
                    style={{ borderRadius: 20 }}
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="登录账号" />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[{ required: true, message: '请输入登录密码' }]}
                >
                  <Input
                    size="large"
                    style={{ borderRadius: 20 }}
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="登录密码"
                  />
                </Form.Item>

                <Form.Item>
                  <Button size="large" htmlType="submit" type='primary' style={{ width: '100%', borderRadius: 20 }}>登录</Button>
                </Form.Item>
                {/* <Form.Item>
                  <Row>
                    <Col flex="60px">
                      <Switch defaultChecked onChange={this.onChangeHandle} />
                    </Col>
                    <Col>
                      记住密码
                    </Col>
                  </Row>
                </Form.Item> */}
              </Form>
            </Col>
          </Row>
        </Content>
      </Layout>
    )
  }

}
export default Login;