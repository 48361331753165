
import md5 from 'md5';
import axios from 'axios';

function getHeader() {
    const token = localStorage.getItem('token');
    const token_type = localStorage.getItem('token_type');
    // const timestamp = Date.now();
    // const signature = md5(token + '94f8816ea98611ec920b0242ac120004' + timestamp);
    // return {
    //     signature,
    //     timestamp,
    //     'admin-id': userId
    // }

    return {
        Authorization: token
    }
}

axios.interceptors.response.use(res => {
    return res
}, err => {
    if (err.response.status === 401) {
        localStorage.setItem('token', '');
        window.location.href = './#/login';
        window.location.reload();
    }
})

// const baseURL = 'https://ant.fgcfh.com.cn/fgPartner';
const baseURL = 'https://patner.huanjiangren.com';

export {
    getHeader,
    baseURL,
    axios
}