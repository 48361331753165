import React from 'react';
import { Row, Col, Table, Input, Button, Modal, Form, message, Menu, TimePicker, Upload, List } from 'antd';
import moment from 'moment';
import './index.css';

import {
  ExclamationCircleOutlined,
  DashboardTwoTone,
  UploadOutlined
} from '@ant-design/icons';

import { getHeader, baseURL, axios } from '../../utils';


const { confirm } = Modal;

class Home extends React.Component {
  state = {
    currentPack: {},
    currentType: 1,
    productList: [
      {
        price: 20,
        rate: '30%',
        id: 1
      }
    ]
  }

  componentDidMount() {
    // this.getProData();
  }

  


  getProData(data = null) {
    message.loading();
    axios({
      url: baseURL + '/admin_api/funds',
      method: 'get',
      headers: getHeader(),
      data: {
        ...data
      }
    }).then(res => {
      const data = res.data;
      console.log('=基金列表=', res)

      if (res.status === 200) {
        this.setState({
          productList: data || []
        });
      }
      message.destroy();

    });
  }

  edit(packData) {
    this.setState({
      packVisible: true,
      isAdd: false,
      currentPack: packData
    });
  }

  add() {
    this.setState({
      currentPack: {},
      isAdd: true,
      packVisible: true
    });
  }

  delete(id) {
    confirm({
      title: '确认要删除这个基金?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: '确定',
      cancelText: '取消',
      onOk: () => {
        message.loading();
        axios({
          url: baseURL + '/admin_api/funds/' + id,
          method: 'delete',
          headers: getHeader(),
        }).then(res => {
          message.destroy();
          if (res.status === 200) {
            message.success('删除成功');
            setTimeout(() => {
              this.getProData();
            }, 1500)
          } else {
            message.error('删除失败' + res.status);
          }

        });
      },
      onCancel() { },
    });
  }
  changeGrand(item) {
    this.setState({
      currentType: item.rand
    })
    this.getProData()

  }
  packSubmit() {
    const { price, rate
    } = this.currentForm.getFieldsValue();
    if (!price) {
      message.warning('请填写红包金额');
      return
    } else if (!rate) {
      message.warning('请填写概率');
      return
    } 

    const { isAdd, currentPack } = this.state;

    const data = isAdd ? {
      price,
      rate
    } : {
      id: currentPack.id,
      price,
      rate
    }

    message.loading();
    axios({
      url: baseURL + (isAdd ? '/admin_api/funds' : '/admin_api/funds/' + currentPack.id),
      method: isAdd ? 'post' : 'put',
      headers: getHeader(),
      data: data
    }).then(res => {
      message.destroy();
      if (res.status === 200) {
        message.success(isAdd ? '添加成功' : '编辑成功');
        this.setState({
          packVisible: false
        });
        setTimeout(() => {
          this.getProData();
        }, 1500);
      } else {
        message.error(isAdd ? '添加失败' : '编辑失败');
      }
    });

  }


 


  render() {

    const {
      productList = [],
      packVisible,
      currentPack,
      currentType,
      isAdd = true
    } = this.state;

    const columns = [{
      title: 'id',
      dataIndex: 'id',
    },
    {
      title: '红包金额(元)',
      dataIndex: 'price'
    },
    {
      title: '获得概率',
      dataIndex: 'rate',
    }, {
      title: '操作',
      dataIndex: 'id',
      render: (id, record) => (
        <div>
          <Button type="link" onClick={() => this.edit(record)}>编辑</Button>
          <Button type="link" onClick={() => this.delete(id)}>删除</Button>
        </div>
      )
    }];
    const gradList = [
      { label: 'V0', key: 'item-1', children: [{ label: '一类', key: 'item-1-1'},{ label: '二类', key: 'item-1-2'} ] },
      { label: 'V1', key: 'item-2', children: [{ label: '一类', key: 'item-2-1'},{ label: '二类', key: 'item-2-2'} ] },
      { label: 'V2', key: 'item-3', children: [{ label: '一类', key: 'item-3-1'},{ label: '二类', key: 'item-3-2'} ] },
      { label: 'V3', key: 'item-4', children: [{ label: '一类', key: 'item-4-1'},{ label: '二类', key: 'item-4-2'} ] },
      { label: 'V4', key: 'item-5', children: [{ label: '一类', key: 'item-4-1'},{ label: '二类', key: 'item-4-2'} ] },
      { label: 'V5', key: 'item-6', children: [{ label: '一类', key: 'item-4-1'},{ label: '二类', key: 'item-4-2'} ] },
      { label: 'V6', key: 'item-7', children: [{ label: '一类', key: 'item-4-1'},{ label: '二类', key: 'item-4-2'} ] },
      { label: 'V7', key: 'item-8', children: [{ label: '一类', key: 'item-4-1'},{ label: '二类', key: 'item-4-2'} ] },

      // {
      //   name: '普通会员',
      //   rand: 1
      // },
      // {
      //   name: '高级会员',
      //   rand: 2
      // },
      // {
      //   name: 'VIP会员',
      //   rand: 3
      // },
      // {
      //   name: 'SVIP会员',
      //   rand: 4
      // },

    ]


    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>

        <Row flex="auto" style={{ background: "#fff", borderRadius: 20, marginTop: 20, padding: '20px 0', minHeight: '80vh' }}>
          <Col span={4} style={{padding: '0 20px', textAlign: 'center', }}>
            <div style={{backgroundColor: '#efefef', height: '100%'}}>
              {/* <p style={{mrgin: 0, padding: '10px 0px', margin: 0}}>老用户拉新</p> */}
              <ul style={{listStyle: 'none', padding: 0, backgroundColor: '#efefef'}}>
                <li style={{borderBottom: '1px solid #ddd', backgroundColor: "#aaa", lineHeight: 3}}><b>会员等级</b></li>
                {/* {
                  // gradList.map(item =>{
                    // return <li 
                    //   style={{borderBottom: '1px solid #ddd', lineHeight: 3, cursor: 'pointer' ,backgroundColor: item.rand == currentType? '#ccc7c7' : ''}}
                    //   onClick={() => this.changeGrand(item)}
                    // >{item.name}</li>
                  })
                } */}
              </ul>
              <Menu mode="inline">
                {
                  gradList.map(item => {
                    return <Menu.SubMenu title={item.label}>
                      {item.children.map(i => {
                        return <Menu.Item>{i.label}</Menu.Item>
                      })}
                    </Menu.SubMenu>
                  })
                }
                
              </Menu>
              

            </div>
          </Col>
          <Col span={20} >
            
            <Row>
              <Button type='primary' style={{marginLeft: '20px'}} onClick={() => this.add()}>新增红包</Button>
            </Row>
            <div style={{marginTop: 20}}>
              <Table style={{ padding: "0 20px" }} rowKey={record => record.id} columns={columns} dataSource={productList} />
              
            </div>
          </Col>


          {/* <Col span={24} style={{ padding: '0 20' }}>
            <Table style={{ padding: "0 20px" }} rowKey={record => record.id} columns={columns} dataSource={productList} />
          </Col> */}
        </Row>



        <Modal
          title={isAdd ? '新增': '编辑'}
          centered
          destroyOnClose={true}
          visible={packVisible}
          onOk={() => this.packSubmit()}
          onCancel={() => { this.setState({ packVisible: false }) }}
          width={500}
        >

          <Form preserve={false} ref={e => { this.currentForm = e; }} initialValues={currentPack}>
            <Form.Item label="红包金额" name='price' rules={[{ required: true, message: '请录入红包金额' }]}>
              <Input type={"number"}/>
            </Form.Item>
            <Form.Item label="获取概率" name='rate' rules={[{ required: true, message: '请录入获取概率' }]}>
              <Input />
            </Form.Item>
            {/* <Form.Item label="年段" name='years' rules={[{ required: true, message: '请录入年段' }]}>
              <Input type={"number"} />
            </Form.Item>
            <Form.Item label="涨幅" name='rises' rules={[{ required: true, message: '请录入涨幅' }]}>
              <Input type={"number"} />
            </Form.Item>
            <Form.Item label="夏普比率" name='sharpe' rules={[{ required: true, message: '请录入夏普比率' }]}>
              <Input />
            </Form.Item> */}



          </Form>
        </Modal>

      </div >
    );
  }
}

export default Home;
