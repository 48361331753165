import React from 'react';
import { Row, Col, Table, Input, Button, Modal, Form, message, Menu, TimePicker, Upload, List } from 'antd';
import moment from 'moment';
import './index.css';

import {
    ExclamationCircleOutlined,
    DashboardTwoTone,
    PlusOutlined
} from '@ant-design/icons';

import { getHeader, baseURL, axios } from '../../utils';


const { confirm } = Modal;

class Home extends React.Component {
    state = {
        currentPack: {},
        currentType: 1,
        combination: '',
    }

    componentDidMount() {
        this.getInitData();
    }

    getInitData() {
        message.loading();
        axios({
            url: baseURL + '/admin_api/configs',
            method: 'get',
            headers: getHeader(),
            data: {},
        }).then(res => {
            const data = res.data || {};
            if (data.code === 200) {
                const { activity_status } = data.data || {};
                console.log(activity_status)
                this.setState({
                    activity_status
                })
            } else {
                message.error('获取活动状态异常')
            }
            message.destroy()
        })
    }

    changeStatus(status) {
        const txtMap = {
            1: '确认要上线活动吗？',
            2: '确认要暂停活动吗？',
            3: '确认要下线活动吗？'
        }
        confirm({
            title: txtMap[status],
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                message.loading();
                axios({
                    url: baseURL + '/admin_api/configs/update',
                    method: 'post',
                    headers: getHeader(),
                    data: {
                        activity_status: status,
                    },
                }).then(res => {
                    const data = res.data;
                    message.destroy();
                    if (data.code === 200) {
                        message.success('设置成功');
                        this.getInitData();
                    } else {
                        message.error('设置失败')
                    }
                });
            },
            onCancel() { },
        });
    }

    uploadButton = (
        <div className="uploadBorder">
            <PlusOutlined />
        </div>
    );

    render() {
        const { activity_status } = this.state;
        const txtMap = {
            1: '在线',
            2: '暂停',
            3: '下线'
        }
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>

                <div flex="auto" style={{ background: "#fff", borderRadius: 20, marginTop: 20, padding: '20px', minHeight: '80vh' }}>
                    {/* <h3 className="lineTitle">banner上传</h3> */}
                    <h2 style={{ margin: '20px 0' }}>活动状态设置</h2>

                    <h3>当前活动状态: {txtMap[activity_status]}</h3>
                    <Row style={{ marginTop: 30 }}>
                        <Col>
                            <Button type="primary" size='large' onClick={() => {
                                this.changeStatus(1)
                            }}>上线</Button>
                            <Button style={{ margin: '0 30px' }} size='large'  onClick={() => {
                                this.changeStatus(2)
                            }}>暂停</Button>
                            <Button danger size='large' onClick={() => {
                                this.changeStatus(3)
                            }}>下线</Button>
                        </Col>
                    </Row>

                </div>
            </div >
        );
    }
}

export default Home;
