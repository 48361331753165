import React from 'react';
import { Row, Col, Table, Input, Button, Modal, Form, message, Radio } from 'antd';
import './index.css';

import {
  ExclamationCircleOutlined
} from '@ant-design/icons';

import { getHeader, baseURL, axios } from '../../utils';

const { Search } = Input;
const { confirm } = Modal;

class Home extends React.Component {
  state = {
    tagModalVisible: false,
    tagList: [
      { index: 1, account: '1234', password: '*****', updated_at: '2023-4-25', role: 1}
    ],
    currentTag: {},
    isAdd: true,
    allList: [],
    page:1,
    limit: 10,
    total: 0
  }

  componentDidMount() {
    this.getTagList();
  }


  getTagList() {

    axios({
      url: baseURL + '/admin_api/users',
      method: 'get',
      headers: getHeader(),
     
    }).then(res => {
      console.log('red', res)
      const data = res.data;
      if (data.code === -1) {
        message.error('获取失败，请稍后重试')
      } else {
        this.setState({
          allList: data.data || [],
          total: data.data.length || 0
        }, () => {
          this.dealTableRow()
        })
      }

    });
  }
  dealTableRow(){
    const { page, limit, allList } = this.state
    let startIndex = (page - 1) * limit
    let endIndex = page * limit
    this.setState({
      tagList: allList.slice(startIndex, endIndex)
    })
  }
  pageChange = (page) => {
    this.setState({
      page: page
    }, () => {
      this.dealTableRow()
    })
  }

 

  edit(packData) {
    this.setState({
      tagModalVisible: true,
      isAdd: false,
      currentTag: packData
    });
  }

  add() {
    this.setState({
      currentTag: {},
      isAdd: true,
      tagModalVisible: true
    });
  }

  delete(id) {
    confirm({
      title: '确认要删除选中?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: '确定',
      cancelText: '取消',
      onOk: () => {
        axios({
          url: baseURL + `/admin_api/users/${id}`,
          method: 'delete',
          headers: getHeader(),
          data: {
          }
        }).then(res => {
          const data = res.data || {};
          message.success(data.msg);
          this.getTagList();

        });
      },
      onCancel() { },
    });
  }

  tagSubmit() {
    const { 
      name, password, role, email
    } = this.currentForm.getFieldsValue();
    const { isAdd, currentTag } = this.state;
    if (!name) {
      message.warning('请填写名称');
      return
    } else if(!email) {
      message.warning('请填写邮箱');
      return
    } else if(!role) {
      message.warning('请选择类型');
      return
    }
    if(isAdd && !password) {
      message.warning('请填写密码');
      return
    }

    

    const data = isAdd ? {
      name, password, role, email
    } : {
      // id: currentTag.id,
      name, password, role, email
    }
    axios({
      url: baseURL + (isAdd ? '/admin_api/users' : `/admin_api/users/${currentTag.id}`),
      method: isAdd ? 'post' : 'put',
      headers: getHeader(),
      data: data
    }).then(res => {
      const data = res.data || {};
      if (data.code === -1) {
        message.error(isAdd ? '添加失败' : '编辑失败');
      } else {
        message.success(isAdd ? '添加成功' : '编辑成功');
        this.setState({
          tagModalVisible: false
        });
        this.getTagList();
      }
    });

  }

 

  render() {

    const {
      tagList = [],
      tagModalVisible,
      currentTag,
      isAdd,
      limit,
      total
    } = this.state;

 

    const columns = [
    {
      title: '序号',
      dataIndex: 'id'
    },
    {
      title: '账号名称',
      dataIndex: 'name'
    },
    {
      title: '账号邮箱',
      dataIndex: 'email'
    },

    {
      title: '密码',
      dataIndex: 'password',
      render:() => (
        <span>*****</span>
      )
    },
    {
      title: '创建时间',
      dataIndex: 'created_at'
    },
    {
      title: '角色',
      dataIndex: 'role',
      render:(role) => (
        <span>{role == 1 ? '运营': '客服'}</span>
      )
    },

    {
      title: '操作',
      dataIndex: 'id',
      render: (id, record) => (
        <div>
          <Button type="link" onClick={() => this.edit(record)}>编辑</Button>
          <Button type="link" onClick={() => this.delete(id)}>删除</Button>
        </div>
      )
    }];




    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>

        <Row flex="auto" style={{ background: "#fff", borderRadius: 20, marginTop: 20, padding: '20px 0'}}>
          <Col span={24} style={{ margin: 30, marginLeft: 20 }}>
            <Button type='primary' size='large' onClick={() => this.add()}>新增</Button>
          </Col>
          <Col span={24} style={{ padding: '0 20'}}>
            <Table 
              style={{ padding: "0 20px" }}
              rowKey={record => record.id}
              columns={columns}
              dataSource={tagList} 
              pagination={{
                total: total,
                pageSize: limit,
                showSizeChanger: false,
                hideOnSinglePage: true,
                onChange: (page, pageSize) => {
                  this.pageChange(page)
                },
                locale: true
              }}
            />
          </Col>
        </Row>


        <Modal
          title={isAdd ? '新增' : '编辑'}
          centered
          destroyOnClose={true}
          visible={tagModalVisible}
          onOk={() => this.tagSubmit()}
          onCancel={() => { this.setState({ tagModalVisible: false }) }}
          width={500}
        >

          <Form preserve={false} ref={e => { this.currentForm = e; }} initialValues={currentTag}>
            
            <Form.Item label="名称" name='name' rules={[{ required: true, message: '请录入账号名称' }]}>
              <Input placeholder="请输入账号"/>
            </Form.Item>
            <Form.Item label="邮箱" name='email' rules={[{ required: true, message: '请录入邮箱' }]}>
              <Input placeholder="请输入邮箱"/>
            </Form.Item>
            {isAdd ?  <Form.Item label="密码" name='password' rules={[{ required: true, message: '请录入密码' }]}>
              <Input placeholder="请输入密码"/>
            </Form.Item>
            : null}
           
            <Form.Item label="账号类型" name='role' rules={[{ required: true, message: '请选择账号类型' }]}>
              <Radio.Group>
                <Radio value={1}>运营</Radio>
                <Radio value={2}>客服</Radio>
                
              </Radio.Group>
            </Form.Item>
          </Form>
        </Modal>


        

      </div >
    );
  }
}

export default Home;
