import React from 'react';
import { Row, Col, Table, Input, Button, Modal, Form, message, Menu, TimePicker, Upload, List } from 'antd';
import moment from 'moment';
import './index.css';

import {
    ExclamationCircleOutlined,
    DashboardTwoTone,
    PlusOutlined
} from '@ant-design/icons';

import { getHeader, baseURL, axios } from '../../utils';


const { confirm } = Modal;

class Home extends React.Component {
    state = {
        currentPack: {},
        currentType: 1,
        combination: '',
    }

    componentDidMount() {
        this.getInitData();
    }

    getInitData() {

        message.loading();
        axios({
            url: baseURL + '/admin_api/configs',
            method: 'get',
            headers: getHeader(),
            data: {},
        }).then(res => {
            const data = res.data || {};
            if (data.code === 200) {
                const { banner, banner_url } = data.data || {};
                this.setState({
                    combination: banner,
                    banner_url
                })
            } else {
                message.error('获取数据异常')
            }
            message.destroy()
        })
    }

    props2 = {
        name: 'pic',
        action: baseURL + ('/admin_api/upload/pic'),
        headers: getHeader(),
        listType: "picture",
        beforeUpload: (file) => {
            const isPNG = file.type === 'image/png';
            if (!isPNG) {
                message.error(`${file.name}不是一个图片`);
            }
            return isPNG || Upload.LIST_IGNORE;
        },
        onChange: ({ file }) => {
            const { status, response } = file
            if (status === 'done') {
                console.log(status);
                const { message: msg, data } = response
                if (data.url) {
                    message.success('上传成功')
                    console.log('this', this)
                    // this.updateBanner(data.url);
                    this.setState({
                        combination: data.url
                    })
                }
            }
        }
    }

    updateBanner() {
        const { combination } = this.state
        const {
            banner_url
        } = this.currentForm.getFieldsValue();
        if (!combination) {
            message.error('选择图片')
            return
        } else if (!banner_url) {
            message.error('输入地址')
            return
        }
        // else if(! /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\*\+,;=.]+$/.test(banner_url)) {
        //   message.error('输入地址正确的地址')
        //   return 
        // }

        message.loading();
        axios({
            url: baseURL + '/admin_api/configs/update',
            method: 'post',
            headers: getHeader(),
            data: {
                banner: combination, //  等级
                banner_url: banner_url
            },
        }).then(res => {
            const data = res.data;
            message.destroy();
            if (data.code === 200) {
                message.success('更新成功');
                this.getInitData();
            } else {
                message.error('更新失败')
            }
        });
    }

    uploadButton = (
        <div className="uploadBorder">
            <PlusOutlined />
        </div>
    );

    render() {

        const { combination, banner_url, initialValueData } = this.state;


        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>

                <div flex="auto" style={{ background: "#fff", borderRadius: 20, marginTop: 20, padding: '20px', minHeight: '80vh' }}>
                    {/* <h3 className="lineTitle">banner上传</h3> */}
                    <Form preserve={false} ref={e => { this.currentForm = e; }} initialValues={{ initialValueData } || {}} >
                        <Form.Item label="选择图片(点击图片选择)" name='combination'>
                            <Upload
                                {...this.props2}
                            >
                                {combination ? <img src={combination} alt="avatar" style={{ width: '100%' }} /> : this.uploadButton}
                            </Upload>
                        </Form.Item>


                        <Row>
                            <Col span={24}>
                                <Form.Item label="当前图片跳转链接" rules={[{ required: true, message: '必填项' }]}>
                                    <Input value={banner_url} disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="新图片跳转链接" name="banner_url" rules={[{ required: true, message: '必填项' }]}>
                            <Input placeholder="图片跳转链接" />
                        </Form.Item>
                        <div style={{ marginTop: 20, textAlign: 'center' }}>
                            <Button size="large" htmlType="submit" type='primary' onClick={() => this.updateBanner()}>提交</Button>
                        </div>
                    </Form>

                </div>
            </div >
        );
    }
}

export default Home;
